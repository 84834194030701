html {
  font-family: "Roboto";
  font-size: 16px;
}

body {
  font-family: "Montserrat";
}

.app {
  min-height: 100vh;
  background-image: url("./images/backgrounds/apples6.jpeg");
  /*background-image: url('./images/sidra/vaso.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  padding: 0 20px 20px;
  text-align: center;
}

.main h1 {
  color: #ddd;
}

.main-content {
  max-width: 900px;
  margin: auto;
  padding: 40px;
  background: rgba(0, 0, 0, 0.5);
  /* https://stackoverflow.com/questions/806000/how-do-i-give-text-or-an-image-a-transparent-background-using-css */
}

.main-form-wrapper {
  /*outline: 1px solid green;*/
  max-width: 500px;
  min-height: 340px;
  margin: 30px auto;
  border-radius: 10px;
  padding: 20px;

  background-image: url("./images/sidra/botella2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0px;
  position: relative;
}

.main-form {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 50px;
  /*background-color: rgba(255, 255, 255, 0.3);*/
  /*background-color: #f0e9e0;
  background: linear-gradient(to right, #a09990, #e0d9d0 25%, #e0d9d0 75%, #a09990); */
}

/* https://github.com/mui-org/material-ui/issues/9574 */

.languages {
  color: #fff;
  font-size: 0.8rem;
  max-width: 900px;
  margin: auto;
  padding: 15px 10px;
  text-align: right;
  position: relative;
}

.languages #logo-header {
  position: absolute;
  top: 12px;
  left: 20px;
}

.languages #logo-header img {
  height: 24px;
}

.languages span {
  cursor: pointer;
  text-decoration: underline;
}

label.MuiFormLabel-root-16 {
  font-size: 20px !important;
  font-weight: bold;
}

.main-form label {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.main-form #standard-number {
  font-size: 1.8rem !important;
  max-width: 200px !important;
}

span.item-id {
  display: block;
  width: 300px;
  height: 120px;
  margin: 0 auto 0;
  padding-top: 10px;

  background-image: url("./images/sidra/etiqueta.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  border: 1px solid #999;

  font-family: Dosis;
  text-decoration: none;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

span.espumosa-item-id  {
  display: block;
  width: 300px;
  height: 120px;
  margin: 0 auto 0;
  padding-top: 10px;

  background-image: url("./images/sidra/etiquetaEspumosa.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  border: 1px solid #999;

  font-family: Dosis;
  text-decoration: none;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

span.espumosa-item-id span.cosecha,
span.espumosa-item-id span.year,
span.item-id span.cosecha,
span.item-id span.year {
  position: absolute;
  font-weight: bold;
  color: #1e7540;
  text-transform: uppercase;
}

span.espumosa-item-id span.cosecha,
span.item-id span.cosecha {
  left: 62%;
  top: 68%;
  font-size: 0.6rem;
}

span.espumosa-item-id  span.year,
span.item-id span.year {
  left: 45%;
  top: 78%;
  font-size: 0.9rem;
}

span.espumosa-item-id span.num-label,
span.item-id  span.num-label {
  position: absolute;
  right: 31px;
  top: 74%;
  font-size: 0.9rem;
  color: #333;
}

span.espumosa-item-id span.num-label span.num, 
span.item-id span.num-label span.num {
  border: 2px solid #c74445;
  border-radius: 0.5em;
  padding:2px;
}

@media (max-width: 4000px) {
  .main-content {
    padding: 20px;
  }

  .main-form-wrapper {
    /*outline: 1px solid green;*/
    background: none;
    min-height: 0;
    position: static;
    background: linear-gradient(
      to right,
      #a09990,
      #e0d9d0 5%,
      #e0d9d0 95%,
      #a09990
    );
  }

  .main-form {
    position: static;
    padding: 10px 10px 5px;
    /*background-color: rgba(255, 255, 255, 0.3);*/
    /*background-color: #f0e9e0;
    background: linear-gradient(to right, #a09990, #e0d9d0 25%, #e0d9d0 75%, #a09990); */
  }
}

@media only screen and (max-width: 400px) {
  span.item-id, span.espumosa-item-id  {
    width: 240px;
    height: 96px;
  }
  span.espumosa-item-id span.cosecha,
  span.item-id span.cosecha {
    left: 52%;
    top: 68%;
    font-size: 0.5rem;
  }
  span.espumosa-item-id span.year,
  span.item-id span.year {
    left: 52%;
    top: 78%;
    font-size: 0.8rem;
  }

  span.espumosa-item-id span.num-label,
  span.item-id span.num-label {
    position: absolute;
    right: 6px;
    top: 74%;
    font-size: 0.7rem;
    color: #333;
  }
}

@media only screen and (max-width: 350px) {
  span.item-id, span.espumosa-item-id  {
    width: 200px;
    height: 80px;
    background-size: 200px 80px;
  }
  span.espumosa-item-id span.cosecha,
  span.item-id span.cosecha {
    left: 52%;
    top: 68%;
    font-size: 0.5rem;
  }
  span.espumosa-item-id span.year,
  span.item-id span.year {
    left: 52%;
    top: 78%;
    font-size: 0.8rem;
  }

  span.espumosa-item-id span.num-label,
  span.item-id span.num-label {
    position: absolute;
    right: 6px;
    top: 74%;
    font-size: 0.7rem;
    color: #333;
  }
}