@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=BenchNine:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Yantramanav:100,300,400,500,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-family: "Roboto";
  font-size: 16px;
}

body {
  font-family: "Montserrat";
}

.app {
  min-height: 100vh;
  background-image: url(/static/media/apples6.fc37c760.jpeg);
  /*background-image: url('./images/sidra/vaso.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  padding: 0 20px 20px;
  text-align: center;
}

.main h1 {
  color: #ddd;
}

.main-content {
  max-width: 900px;
  margin: auto;
  padding: 40px;
  background: rgba(0, 0, 0, 0.5);
  /* https://stackoverflow.com/questions/806000/how-do-i-give-text-or-an-image-a-transparent-background-using-css */
}

.main-form-wrapper {
  /*outline: 1px solid green;*/
  max-width: 500px;
  min-height: 340px;
  margin: 30px auto;
  border-radius: 10px;
  padding: 20px;

  background-image: url(/static/media/botella2.7eb1635a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0px;
  position: relative;
}

.main-form {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 50px;
  /*background-color: rgba(255, 255, 255, 0.3);*/
  /*background-color: #f0e9e0;
  background: linear-gradient(to right, #a09990, #e0d9d0 25%, #e0d9d0 75%, #a09990); */
}

/* https://github.com/mui-org/material-ui/issues/9574 */

.languages {
  color: #fff;
  font-size: 0.8rem;
  max-width: 900px;
  margin: auto;
  padding: 15px 10px;
  text-align: right;
  position: relative;
}

.languages #logo-header {
  position: absolute;
  top: 12px;
  left: 20px;
}

.languages #logo-header img {
  height: 24px;
}

.languages span {
  cursor: pointer;
  text-decoration: underline;
}

label.MuiFormLabel-root-16 {
  font-size: 20px !important;
  font-weight: bold;
}

.main-form label {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.main-form #standard-number {
  font-size: 1.8rem !important;
  max-width: 200px !important;
}

span.item-id {
  display: block;
  width: 300px;
  height: 120px;
  margin: 0 auto 0;
  padding-top: 10px;

  background-image: url(/static/media/etiqueta.0674d960.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  border: 1px solid #999;

  font-family: Dosis;
  text-decoration: none;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

span.espumosa-item-id  {
  display: block;
  width: 300px;
  height: 120px;
  margin: 0 auto 0;
  padding-top: 10px;

  background-image: url(/static/media/etiquetaEspumosa.66bd0858.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  border: 1px solid #999;

  font-family: Dosis;
  text-decoration: none;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

span.espumosa-item-id span.cosecha,
span.espumosa-item-id span.year,
span.item-id span.cosecha,
span.item-id span.year {
  position: absolute;
  font-weight: bold;
  color: #1e7540;
  text-transform: uppercase;
}

span.espumosa-item-id span.cosecha,
span.item-id span.cosecha {
  left: 62%;
  top: 68%;
  font-size: 0.6rem;
}

span.espumosa-item-id  span.year,
span.item-id span.year {
  left: 45%;
  top: 78%;
  font-size: 0.9rem;
}

span.espumosa-item-id span.num-label,
span.item-id  span.num-label {
  position: absolute;
  right: 31px;
  top: 74%;
  font-size: 0.9rem;
  color: #333;
}

span.espumosa-item-id span.num-label span.num, 
span.item-id span.num-label span.num {
  border: 2px solid #c74445;
  border-radius: 0.5em;
  padding:2px;
}

@media (max-width: 4000px) {
  .main-content {
    padding: 20px;
  }

  .main-form-wrapper {
    /*outline: 1px solid green;*/
    background: none;
    min-height: 0;
    position: static;
    background: linear-gradient(
      to right,
      #a09990,
      #e0d9d0 5%,
      #e0d9d0 95%,
      #a09990
    );
  }

  .main-form {
    position: static;
    padding: 10px 10px 5px;
    /*background-color: rgba(255, 255, 255, 0.3);*/
    /*background-color: #f0e9e0;
    background: linear-gradient(to right, #a09990, #e0d9d0 25%, #e0d9d0 75%, #a09990); */
  }
}

@media only screen and (max-width: 400px) {
  span.item-id, span.espumosa-item-id  {
    width: 240px;
    height: 96px;
  }
  span.espumosa-item-id span.cosecha,
  span.item-id span.cosecha {
    left: 52%;
    top: 68%;
    font-size: 0.5rem;
  }
  span.espumosa-item-id span.year,
  span.item-id span.year {
    left: 52%;
    top: 78%;
    font-size: 0.8rem;
  }

  span.espumosa-item-id span.num-label,
  span.item-id span.num-label {
    position: absolute;
    right: 6px;
    top: 74%;
    font-size: 0.7rem;
    color: #333;
  }
}

@media only screen and (max-width: 350px) {
  span.item-id, span.espumosa-item-id  {
    width: 200px;
    height: 80px;
    background-size: 200px 80px;
  }
  span.espumosa-item-id span.cosecha,
  span.item-id span.cosecha {
    left: 52%;
    top: 68%;
    font-size: 0.5rem;
  }
  span.espumosa-item-id span.year,
  span.item-id span.year {
    left: 52%;
    top: 78%;
    font-size: 0.8rem;
  }

  span.espumosa-item-id span.num-label,
  span.item-id span.num-label {
    position: absolute;
    right: 6px;
    top: 74%;
    font-size: 0.7rem;
    color: #333;
  }
}
/*** CONTAINER - GENERAL STYLES - BACKGROUND ***/

.timeline-container {
    width: 100%;
    max-width: 980px;
    margin: 30px auto;
    font-size: 1em;
    padding: 20px 20px 0 20px;
    background-color: #fff;
   /* border: 2px solid #c0c0e0;*/
    border-radius: 10px;
}

.timeline-container ol,
.timeline-container ul,
.timeline-container li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/*** TIMELINE MAIN LIST (OL) ***/

ol.timeline-main{
    font-family: 'Dosis', 'Arial', 'Tahoma', 'Verdana', sans-serif;
    font-weight: 300;
    position: relative;
    padding: 40px 0;
}

ol.timeline-main:after{
    content: '';
    display: block;
    clear: both;
} 

/*** TIMELINE ITEM (LI) ***/

li.timeline-item {
    color: #000;
    /*width: 30.1%;*/
    float: left;
    position: relative;
    clear:both;
}

li.timeline-item.right:before,
li.timeline-item.left:before {
    content: ''; 
    background-color: none;
    height: 100%;
    width: 16px;
    position: absolute;
    top: 0;
    border: 8px solid #5A983F;
    border-top: none;
    border-bottom: none;
}

li.timeline-item.right:before{
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 0;
}

li.timeline-item.left:before{
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    right: 0;
}

li:first-child.timeline-item.right:before {
    border: 8px dashed #3A76d8;
}

li:last-child.timeline-item.right:before {
    border: none;
}

/*** TIMELINE CONTENT ***/

.timeline-content{
    color: #000;
    padding: 10px 150px 0 0;
    display: block;
    position: relative;
}
.timeline-content:hover{
    color: #000;
    text-decoration: none;
}

.timeline-content:before,
.timeline-content:after{
    content: '';
    background-color: #000;
    height: 2px;
    width: 100px;
    position: absolute;
    right: 42px;
    top: 4px;
}
.timeline-content:after{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 0;
    right: 133px;
}

/*** TIMELINE POINT ***/

li.timeline-item.right .timepoint,
li.timeline-item.left .timepoint{
    background-color: #5A983F;
    font-size: 0.95rem;
    font-weight: 400;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 60px;
    box-shadow: 0 0 7px #fff inset,0 0 0 6px #4A882F;    border-radius: 50%;
    position: absolute;
    right: -30px;
    top: 0;
    color: #fff;
}

li:first-child.timeline-item .timepoint {
    background-color: #3A76d8;
    box-shadow: 0 0 7px #fff inset,0 0 0 6px #2D64c4;
}

li.timeline-item.left .timepoint{
    background-color: #5A983F;
    line-height: 40px;
    height: 40px;
    width: 40px;
    right: -20px;
    box-shadow: none;
}


/*** INNER CONTENT TYPOGRAPHY ***/

.timeline-content .inner-content .title{
    color: #274E13;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 0 0;
}
.timeline-content .inner-content .subtitle{
    color: #666;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
}
.timeline-content .inner-content .title i{
    font-size: 1.6rem;
    margin-top: 5px;
    margin-left: 5px;
}
.timeline-content .inner-content .description{
    font-size: 0.95rem;
    letter-spacing: 1px;
}

/*** TYPES ***/

li.timeline-item.right { 
    float: right; 
    width: 70%;
}
li.timeline-item.left { 
    float: left; 
    width: 30%;
}
li.timeline-item.right .timeline-content:before{
    right: auto;
    left: 42px;
}
li.timeline-item.right .timeline-content:after{
    right: auto;
    left: 133px;
}
li.timeline-item.right .timeline-content{ padding: 10px 0 0 150px; }
li.timeline-item.right .timepoint{
    right: auto;
    left: -29px;
}


/*** OTHER CONTENT ***/

h2 {
    font-family: 'Dosis', 'Arial', 'Tahoma', 'Verdana', sans-serif;
    text-align: center;
    color: #274E13;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
    display: none;
}

a.item-id {
    display: block;
    width: 300px;
    height: 120px;
    margin: 10px auto 0;

    background-image: url(/static/media/etiqueta.0674d960.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    border: 1px solid #999;

    font-family: Dosis;
    text-decoration: none;
    position: relative;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
}

a.espumosa-item-id {
    display: block;
    width: 300px;
    height: 120px;
    margin: 10px auto 0;

    background-image: url(/static/media/etiquetaEspumosa.66bd0858.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    border: 1px solid #999;

    font-family: Dosis;
    text-decoration: none;
    position: relative;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
}

a.espumosa-item-id:hover, a.item-id:hover,
a.espumosa-item-id:focus, a.item-id:focus {
    box-shadow: 2px 2px 15px 2px rgba(0,0,0,0.2);
    /*https://tobiasahlin.com/blog/how-to-animate-box-shadow/*/
}

a.espumosa-item-id span.cosecha,
a.espumosa-item-id span.year,
a.item-id span.cosecha,
a.item-id span.year {
    position: absolute;
    font-weight: bold;
    color: #1e7540;
    text-transform: uppercase;
}
a.espumosa-item-id span.cosecha,
a.item-id span.cosecha {
    left: 52%;
    top: 68%;
    font-size: 0.6rem;
    

}
a.espumosa-item-id span.year,
a.item-id span.year {
    left: 52%;
    top: 78%;
    font-size: 0.9rem;
}
a.espumosa-item-id span.num-label{ 
    position: absolute;
    right: 40px;
    top: 77%;
    font-size: 0.9rem;
    color: #333;
}

a.item-id span.num-label { 
    position: absolute;
    right: 40px;
    top: 75%;
    font-size: 0.9rem;
    color: #333;
}

span.item-batch {
    color:#000000;
    text-align: center;
    display:block;
    font-size: 1rem;
}

span.item-tasting {
    color:#000000;
    background-color: #d9e8da;
    text-align: center;
    display:block;
    margin: 0 auto 0;
    padding: 10px;
    font-size: 0.8rem;
    font-style: italic;
    width: 300px;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
}

table.locations {
    margin: 10px 0 20px;
}

table.locations td { 
    padding: 5px 10px 5px 0
}

a.appleLink{
    display: inline-block;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0);
  }
a.appleLink:hover, 
a.appleLink:focus{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.75);
    transition: box-shadow 0.7s;
}


/*** MEDIA QUERIES ***/

@media only screen and (max-width:400px){    

    a.item-id, a.espumosa-item-id {
        width: 240px;
        height: 96px;
    }

    span.item-tasting {
        width: 240px;
    }

    a.espumosa-item-id span.cosecha, 
    a.item-id span.cosecha {
        left: 52%;
        top: 68%;
        font-size: 0.5rem;   
    }
    a.item-id span.year,
    a.espumosa-item-id span.year {
        left: 52%;
        top: 78%;
        font-size: 0.8rem;
    }

    a.espumosa-item-id span.num-label { 
        position: absolute;
        right: 40px;
        top: 76%;
        font-size: 0.9rem;
        color: #333;
    }

    a.item-id span.num-label { 
        position: absolute;
        right: 40px;
        top: 75%;
        font-size: 0.9rem;
        color: #333;
    }
}

@media only screen and (max-width:990px){
    li.timeline-item:before{ width: 50px; }
    li.timeline-item:after{ right: 90px; }
    .timeline-content{ padding: 10px 80px 0 0; }
    li.timeline-item.right:after{ left: 90px; }
    li.timeline-item.right .timeline-content{ padding: 10px 0 0 80px; }
}
@media only screen and (max-width:767px){
    /*.timeline-main:before{
        transform: translateX(0);
        left: 15px;
    }
    */
    li.timeline-item.left:before{
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 30px;
    }

    li.timeline-item.right:before{
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 30px;
    }

    li.timeline-item.right, 
    li.timeline-item.left {
        width: 100% !important;
        padding-bottom: 30px;
    }
    /*
    li.timeline-item:before,
    li.timeline-item.right:before{
        left: 80px;
    }
    li.timeline-item:after,
    li.timeline-item.right:after{
        left: 120px;
    }
    */

    li.timeline-item.left .timeline-content:before{
        right: auto;
        left: 42px;
    }
    li.timeline-item.left .timeline-content:after{
        right: auto;
        left: 133px;
    }

    .timepoint,
    li.timeline-item.right .timepoint{
        left: 0;
    }
    li.timeline-item.left .timepoint{
        left: 10px;
    }
    .timeline-content{ padding: 10px 0 0 80px; }
    .timeline-content .title{ font-size: 20px; }
    .timeline-content .title i{ font-size: 25px; }
    .timeline-content .inner-content .description{ font-size: 0.8125rem; }
}




img.not-found {
    display: block;
    margin: 20px auto 0 auto;
    max-width: 240px;
  }
  
  @media only screen and (min-width: 601px) {
    img.not-found {
      max-width: 400px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    img.not-found {
      max-width: 500px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    img.not-found {
      max-width: 700px;
    }
  }

  div.check-share{
    display: block;
    margin: 0 -20px 0 -20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-top:5px;
    padding-bottom:30px;
    border-top:1px solid #ccc;
    text-align:center;
    background: #f0f0f0
  }
.timeline-page {
    padding: 0 20px 20px;
}

.logo-dop {
    text-align: center;
}

.logo-ctic {
    text-align: center;
    margin: 30px 0 0;
}



.timeline-page .MuiSvgIcon-root-224,
.description a[role="button"] span {
    font-size: 20px;
}

.timeline-page .MuiButton-sizeSmall-24,
.description a[role="button"] {
    min-width: 48px;
    width: 48px;
    padding: 4px 4px;
}



@media (max-width: 359px) {
    .timeline-page {
        padding: 0 0px 20px;
    }
    .timeline-page .main-content{
        padding: 20px 0;
    }

    .timeline-container {
        border-radius: 0;
    }
}
