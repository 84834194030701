.timeline-page {
    padding: 0 20px 20px;
}

.logo-dop {
    text-align: center;
}

.logo-ctic {
    text-align: center;
    margin: 30px 0 0;
}



.timeline-page .MuiSvgIcon-root-224,
.description a[role="button"] span {
    font-size: 20px;
}

.timeline-page .MuiButton-sizeSmall-24,
.description a[role="button"] {
    min-width: 48px;
    width: 48px;
    padding: 4px 4px;
}



@media (max-width: 359px) {
    .timeline-page {
        padding: 0 0px 20px;
    }
    .timeline-page .main-content{
        padding: 20px 0;
    }

    .timeline-container {
        border-radius: 0;
    }
}